@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --Primary-color: #008b13;
  --Primary-white: #ffffff;
  --Primary-red: #f93a00;
  --Primary-black: #000000;
  --Primary-gray: #666666;
  --Primary-light-grey: #f4f4f4;
  --Secondary-gray: #c2c1c1;
}

body {
font-family: "Roboto Condensed", sans-serif !important;
}

/************************ NAVBAR *********************************/
nav {
  z-index: 2;
}

.logo {
  width: 26.25rem;
  height: 6.25rem;
}

nav a.nav-link {
  font-size: 1rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--Primary-color) !important;
  border-top: 0.1875rem solid var(--Primary-white);
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-brand {
  max-width: 17.25rem !important;
  padding: 0.25rem !important;
  height: 6rem !important;
}

.navbar-nav > li > a.active,  .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--Primary-color);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--Primary-color);
}

/************************ Header *********************************/
.header-wraper {
  position: relative;
  background: url(./background.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 80vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--Primary-white);
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: var(--Primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;

  }
}

.btn-main-offer {
  /* border: 1px solid var(Primary-color); */
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--Primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--Primary-color);
  color: var(--Primary-white);
  transition:  .2s ease-in-out;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/************************ About Us *********************************/
.about-us {
  background: var(--Primary-light-grey);
  padding: 3rem 0;
}

.photo-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img{
  width: 18rem;
  border-radius:  5%;
  padding: .3rem;
  border: 1px solid var(--Primary-color);
}

.about-heading{
  color: var(--Primary-color);
  text-transform: uppercase;
}

@media(max-width: 468px){
  .profile-img{
    width: 10rem;
  }
}

/************************ Contact Us *********************************/
.contacts{
  background: var(--Primary-light-grey);
  padding: 3rem 0;
}

.contacts h1{
  color: var(--Primary-color);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p{
  color: var(--Primary-color);
  font-size: 1.2rem;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: var(--Primary-white);
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--Primary-white);
  border-radius: 0;
  color: var(--Primary-color);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: var(--Secondary-gray);
  color: var(--Primary-white);
  border-bottom: 0.125rem solid var(--Primary-white);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  color:var(--Primary-color)
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--Primary-color);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--Primary-white);
  background: var(--Primary-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--Primary-color);
}

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  
  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/************************ Footer *********************************/
.footer {
  background: var(--Primary-gray);
  color: var(--Primary-white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--Primary-white);
}

.footer a:hover {
  text-decoration: none;
  color: var(--Primary-color);
  cursor:pointer;
}

.footer p {
  margin-bottom: 0;
  text-align: left;

}

.footer i {
  text-decoration: none;
  color: var(--Primary-white);
}

.pt-3 {
  margin-bottom: 0;
  text-align: center;
  color: var(--Primary-white);
  font-size: 1rem !important;

}

.fot-link {
  font-size: .8rem;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

/************************ SERVICES *********************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--Primary-white);
}

.services li {
  color: var(--Primary-color);
}

.services li:hover {
  color: var(--Primary-white);
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--Primary-color);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--Primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 19.8rem;
  width: 18rem;
  border-bottom: 0.3125rem solid var(--Primary-color);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--Primary-white);
}

.services .box:hover {
  background: var(--Primary-color);
  border-bottom: 0.3125rem solid var(--Primary-black);
  color: var(--Primary-white);
}

.services .box:hover .icon {
  background: var(--Primary-color);
}

.services .box:hover .circle {
  background: var(--Primary-white);
}

.services h1 {
  color: var(--Primary-color);
  text-transform: uppercase;
  font-size: 2.6rem;
}

.services .btn{
  background-color: var(--Primary-color);
  color: var(--Primary-white);
  border: none;
  padding:5px;
  border-radius: 5px;
  cursor:pointer;
  font-size: 20px;
}

.services .btn:hover{
  background-color: var(--Primary-white);
  color: var(--Primary-color);
  border: none;
  padding:5px;
  border-radius: 5px;
  cursor:pointer;
  font-size: 20px;
}

.services .box2 {
  height: auto;
  width: 45rem;
  border-bottom: 0.3125rem solid var(--Primary-color);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--Primary-white);
  border-color: 0.3125rem solid var(--Primary-color);
  text-align: left;
  align-content: space-between;
  margin-top: 2rem;
  margin-left: -30rem;

  
}

.services .box2:hover {
  background: var(--Primary-color);
  border-bottom: 0.3125rem solid var(--Primary-black);
  color: var(--Primary-white);
}

.services .box2:hover .icon {
  background: var(--Primary-color);
}

.services .box2:hover .circle {
  background: var(--Primary-white);
}

/************************ Pages *********************************/
.pages {
  background: var(--Primary-white);
  padding: 3rem 0;
}

.pages-photo-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages-profile-img{
  width: 25rem;
  border-radius:  5%;
  padding: .3rem;
  border: 1px solid var(--Primary-color);
}

.pages-heading{
  color: var(--Primary-color);
  text-transform: uppercase;
}

@media(max-width: 768px){
  .pages.profile-img{
    width: 20rem;
  }
}

/************************ Maps *********************************/
path {
  pointer-events: all;
}
path:hover {
  opacity: 0.50;
  cursor: pointer;
}

.maps {
  background: var(--Primary-white);
  /* padding: 1rem 0; */
  align-items: center;
}

.maps h1 {
  color: var(--Primary-color);
  text-transform: uppercase;
  font-size: 2.6rem;
  /* padding: 1rem 0; */
  text-align: center;
}

.maps-photo-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.maps .container {
  /* padding: 1rem 0; */
}


